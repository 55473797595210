











import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class MdPopup extends Vue {
  @Prop({ default: true }) isOverlay?: boolean;
  @Prop({ default: '' }) overlayClass?: string;
  @Prop({ default: true }) closeOnClickOverlay?: boolean;
  @Prop({ default: false }) show?: boolean;
  @Prop({ default: 'bottom' }) position?: string;
  @Prop({ default: true }) isRound?: boolean;
  @Prop({ default: 300 }) duration?: number;
  @Prop({ default: true }) lockScroll?: boolean;
  private isShowOverlay: boolean = false;

  mounted() {
    if (this.show) {
      this.isShowOverlay = true
    }
  }

  closePopup() {
    if (this.closeOnClickOverlay) {
      //popup开始关闭时触发
      this.$emit('update:show', false)
    }
  }

  @Watch('show')
  showChange(value: any) {
    this.$nextTick(() => {
      if (value) {
        //打开popup时触发
        this.$emit('open')
        this.isShowOverlay = value
        if (this.lockScroll) {
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
          window.scrollTo(0, scrollTop + 1)
          document.body.style.overflow = 'hidden'
        }
        setTimeout(() => {
          //popup打开且动画完成时触发
          this.$emit('opened')
        }, this.duration)
      } else {
        this.$emit('close', false)
        setTimeout(() => {
          this.isShowOverlay = value
          //popup关闭且动画结束时触发触发
          this.$nextTick(() => {
            this.$emit('closed')
            if (this.lockScroll) {
              document.body.style.overflow = 'auto'
            }
          })
        }, this.duration)
      }
    })
  }
}
