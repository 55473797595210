<template>
  <div id="main">
    <div id="banner">
      <img class="banner-t20" :src='require("@/assets/img/product/t20/t20.png")'>
      <p class="banner-series">T20 series</p>
      <p class="banner-title">澜渟T20·新一代<br/>盆底肌修复仪</p>
      <p class="banner-subTitle">精准治疗 紧致升级</p>
      <p class="banner-verify">
        南京麦豆健康科技有限公司<br/>
        医疗器械注册证编号：苏械注准20242090347<br/>
        广告审查批准文号：苏械广审(文)第260411-06435号<br/>
        请仔细阅读产品说明书或者在医务人员的指导下购买和使用
      </p>
      <p class="banner-tip-one">仅在中国指定授权机构销售<span>*</span></p>
      <p class="banner-tip-two"><span>*</span>仅涉及T20s型号</p>
    </div>
    <div id="video" class="content-item">
      <div class="video-container" @mouseenter="isVideoHover = true" @mouseleave="isVideoHover = false">
        <video class="video" ref="myVideo" @click="playVideo" :src="videoPath" muted
               :poster="videoPath + '?x-oss-process=video/snapshot,t_0,f_jpg,m_fast'"
               @pause="playState(false)" @play="playState(true)">
        </video>
        <img v-if="!isVideoPlaying" class="playBtn" @click.stop="playVideo"
             :src='require("@/assets/img/product/t20/play_circle.png")'/>
        <img v-if="isVideoPlaying && isVideoHover" class="playBtn" @click.stop="pauseVideo"
             :src='require("@/assets/img/product/t20/pause_circle.png")'/>
      </div>
    </div>
    <div id="apply" class="content-item">
      <p class="apply-title">澜渟T20 · 新一代 盆底肌修复仪</p>
      <p class="apply-subTitle">治疗盆底症状+阴道松弛</p>
      <p class="apply-content">/ 适用于 /</p>
      <div class="apply-for">
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_1.png")'/>
          <span class="for-item-text">治疗压力<br/>性尿失禁</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_2.png")'/>
          <span class="for-item-text">治疗盆腔<br/>器官脱垂</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_3.png")'/>
          <span class="for-item-text">缓解慢性<br/>盆腔疼痛</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_4.png")'/>
          <span class="for-item-text">提升盆底<br/>肌肉健康</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_5.png")'/>
          <span class="for-item-text">治疗阴道<br/>松弛症</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_6.png")'/>
          <span class="for-item-text">增强阴道<br/>收缩功能</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_7.png")'/>
          <span class="for-item-text">促进阴道<br/>产后复旧</span>
        </div>
        <div class="for-item">
          <img class="for-item-img" :src='require("@/assets/img/product/t20/disease_8.png")'/>
          <span class="for-item-text">治疗女性<br/>性功能障碍</span>
        </div>
      </div>
    </div>
    <div id="technology" class="content-item">
      <span class="technology-title">两大核心技术</span>
      <span class="technology-subTitle">神经肌肉电刺激+肌电生物反馈</span>
      <span class="technology-desc left">神经肌肉电刺激</span>
      <img class="technology-img left" :src='require("@/assets/img/product/t20/technology-left.gif")'/>
      <span class="technology-desc right">肌电生物反馈</span>
      <img class="technology-img right" :src='require("@/assets/img/product/t20/technology-right.gif")'/>
    </div>
    <div id="system" class="content-item">
      <span class="system-title">双重修复 紧致系统</span>
      <span class="system-subTitle">盆底系统+私密系统</span>
      <img class="system-img left" :src='require("@/assets/img/product/t20/system-img-left.png")'/>
      <img class="system-img right" :src='require("@/assets/img/product/t20/system-img-right.png")'/>
    </div>
    <div id="pelvic" class="content-item">
      <span class="pelvic-title">第一重·盆底系统</span>
      <span class="pelvic-subTitle">唤活盆底 改善松弛</span>
      <img class="pelvic-img right" :src='require("@/assets/img/product/t20/pelvic-img-right.png")'/>
      <img class="pelvic-img left" :src='require("@/assets/img/product/t20/pelvic-img-left.gif")'/>
    </div>
    <div id="dimension" class="content-item">
      <span class="dimension-title">多维评估 还原大设备报告</span>
      <span class="dimension-subTitle">6分17秒Glazer评估 国际黄金标准</span>
      <span class="dimension-subTitle extra">6个盆底评估维度、15项评估指标，同理麦澜德大设备报告分析</span>
      <img class="dimension-img" :src='require("@/assets/img/product/t20/dimension_img.png")'/>
    </div>
    <div id="wireless" class="content-item">
      <div class="wireless-title">无线腹肌监测<br/>提升训练有效性</div>
      <div class="wireless-subTitle">独立无线腹肌监测主机<br/>清晰了解腹肌参与情况</div>
      <img class="wireless-img right" :src='require("@/assets/img/product/t20/wireless-img-right.png")'/>
      <img class="wireless-img left" :src='require("@/assets/img/product/t20/wireless-img-left.png")'/>
    </div>
    <div id="privacy" class="content-item">
      <span class="privacy-title">第二重·私密系统</span>
      <span class="privacy-subTitle">增强收缩 紧致阴道</span>
      <img class="privacy-img left" :src='require("@/assets/img/product/t20/privacy-img-left.png")'/>
      <img class="privacy-img right" :src='require("@/assets/img/product/t20/privacy-img-right.png")'/>
    </div>
    <div id="assess" class="content-item">
      <div class="assess-title">权威双向评估<br/>科学分析阴道状态</div>
      <span class="assess-subTitle">进阶版FSFI量表+6分17秒·Glazer评估双向报告</span>
      <span class="assess-subTitle extra">5个评估维度、21项评估指标，全面评估阴道健康度</span>
      <img class="assess-img" :src='require("@/assets/img/product/t20/assess_img.png")'/>
    </div>
    <div id="train" class="content-item">
      <div class="train-title">科学训练<br/>提升阴道细胞活力</div>
      <span class="train-subTitle">阴道疲劳智能监测 科学调整训练模板</span>
      <img class="train-img" :src='require("@/assets/img/product/t20/train_img.png")'/>
    </div>
    <div id="protect" class="content-item">
      <div class="protect-title">双重护航<br/>建立个人专属训练模式</div>
      <span class="protect-subTitle">智能AI顾问动态调整盆底肌/阴道训练方案</span>
      <span class="protect-subTitle extra">GMAA盆底健康顾问终身陪伴服务</span>
      <img class="protect-img" :src='require("@/assets/img/product/t20/protect_img.png")'/>
    </div>
    <div id="pattern" class="content-item">
      <span class="pattern-title">五大训练模式 科学修复盆底+私密</span>
      <span class="pattern-subTitle">多态模式加护盆底 促进产后阴道修复</span>
      <img class="pattern-img" :src='require("@/assets/img/product/t20/pattern_img.png")'/>
    </div>
    <div id="expr" class="content-item">
      <span class="expr-title">无线体验升级 随享无拘无束</span>
      <span class="expr-subTitle">6重设计 操作更简单</span>
      <img class="expr-img" :src='require("@/assets/img/product/t20/expr_img.png")'/>
      <div class="expr-item one">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_1.png")'/>
        <span class="expr-item-title">内置自体消毒</span>
        <span class="expr-item-desc">UVC灯珠 紫外线消毒</span>
      </div>
      <div class="expr-item two">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_2.png")'/>
        <span class="expr-item-title">无线一体化设计</span>
        <span class="expr-item-desc">一键开启 摆脱有线束缚</span>
      </div>
      <div class="expr-item three">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_3.png")'/>
        <span class="expr-item-title">充电收纳一体</span>
        <span class="expr-item-desc">保护机身 长效护航</span>
      </div>
      <div class="expr-item four">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_4.png")'/>
        <span class="expr-item-title">无线温热探头</span>
        <span class="expr-item-desc">入体更舒适</span>
      </div>
      <div class="expr-item five">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_5.png")'/>
        <span class="expr-item-title">温柔震感提醒</span>
        <span class="expr-item-desc">训练异常实时感知</span>
      </div>
      <div class="expr-item six">
        <img class="expr-item-img" :src='require("@/assets/img/product/t20/expr_6.png")'/>
        <span class="expr-item-title">1000V耐压技术</span>
        <span class="expr-item-desc">科技实力享体验</span>
      </div>
    </div>
    <div id="connect" class="content-item">
      <img class="connect-circle" :src='require("@/assets/img/product/t20/new_circle.gif")'/>
      <span class="connect-title">一键连接 智能训练</span>
      <span class="connect-subTitle">产品通过蓝牙连接配套APP，享受简单愉悦的智能训练体验</span>
      <img class="connect-img left" :src='require("@/assets/img/product/t20/connect-img-left.png")'/>
      <img class="connect-img right" :src='require("@/assets/img/product/t20/connect-img-right.png")'/>
      <img class="connect-img append" :src='require("@/assets/img/product/t20/connect-img-append.png")'/>
    </div>
    <div id="research" class="content-item">
      <span class="research-title">国际权威验证 专研盆底紧致</span>
      <span class="research-subTitle">《电刺激生物反馈在产后盆底 功能康复中的临床效果评价》</span>
      <span class="research-label">尿失禁临床研究[1]</span>
      <img class="research-img" :src='require("@/assets/img/product/t20/research_img.png")'/>
      <span class="research-tip">[1]参考文献：刘盼,李海燕,张庆. 电刺激生物反馈在产后盆底功能康复中的临床效果评价[J]. 生殖医学杂志, 2024, 23 (4): 481-486</span>
    </div>
    <div id="patent" class="content-item">
      <span class="patent-title">国家医疗器械认证及专利</span>
      <span class="patent-subTitle">严苛品质</span>
      <div class="patent-list">
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_1.png")'/>
          <span class="patent-item-name">盆底康复治疗仪</span>
          <span class="patent-item-desc">二类医疗器械</span>
        </div>
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_2.png")'/>
          <span class="patent-item-name">发明专利</span>
          <span class="patent-item-desc">ZL 2023 1 1543573.4</span>
        </div>
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_3.png")'/>
          <span class="patent-item-name">发明专利</span>
          <span class="patent-item-desc">ZL 2023 1 0913296.5</span>
        </div>
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_4.png")'/>
          <span class="patent-item-name">发明专利</span>
          <span class="patent-item-desc">ZL 2023 1 1802368.5</span>
        </div>
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_5.png")'/>
          <span class="patent-item-name">外观设计专利</span>
          <span class="patent-item-desc">ZL202330448897.4</span>
        </div>
        <div class="patent-item">
          <img class="patent-item-img" :src='require("@/assets/img/product/t20/patent_6.png")'/>
          <span class="patent-item-name">外观设计专利</span>
          <span class="patent-item-desc">ZL 2023 3 0481048.9</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MdPopup from '../../components/popup/src/popup';

export default {
  components: {MdPopup},
  data() {
    return {
      videoPath: `//rightinhome.oss-cn-hangzhou.aliyuncs.com/train_prepare_video/${process.env.NODE_ENV === 'prod' ? '' : 'test/'}website_t20.mp4`,
      isVideoPlaying: false,
      isVideoHover: false,
      isClickPause: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    // window.addEventListener('scroll', this.pageScroll);
    setInterval(() => {
      this.pageScroll()
    }, 1000);
  },
  destroyed() {
    // window.removeEventListener('scroll', this.pageScroll);
  },
  methods: {
    pageScroll() {
      if (this.isClickPause) {
        return;
      }
      // 判断 video是不是被 header sh
      const videoDom = document.querySelector('.video-container');
      const headerDom = document.querySelector('#top');
      if (videoDom && headerDom) {
        // videoDom 在 屏幕中间
        if (videoDom.getBoundingClientRect().top + videoDom.getBoundingClientRect().height / 2 > headerDom.getBoundingClientRect().height &&
          window.innerHeight - videoDom.getBoundingClientRect().top > videoDom.getBoundingClientRect().height / 2) {
          this.$refs.myVideo.play();
          this.isVideoPlaying = true;
        } else {
          this.$refs.myVideo.pause();
          this.isVideoPlaying = false;
        }
      }
    },
    playState(isPlay) {
      this.isVideoPlaying = isPlay;
      if (isPlay) {
        this.$refs.myVideo.setAttribute('controls', 'true');
      } else {
        this.$refs.myVideo.removeAttribute('controls');
      }
    },
    playVideo() {
      this.$refs.myVideo.muted = false;
      this.$refs.myVideo.play();
    },
    pauseVideo() {
      this.isClickPause = true;
      this.$refs.myVideo.pause();
    },
  },
};
</script>

<style lang="scss" scoped>
$md-color-primary: #5A53CD;

#banner {
  position: relative;
  height: 6.6rem;
  background: url(../../assets/img/product/t20/banner-bg.png) no-repeat center center;
  background-size: 100% 100%;

  > .banner-t20 {
    position: absolute;
    top: 0.33rem;
    left: 3.12rem;
    width: 7.08rem;
  }

  > .banner-series {
    position: absolute;
    width: 1.63rem;
    height: 0.37rem;
    border-radius: 0.19rem;
    top: 1.72rem;
    right: 3.6rem;
    line-height: 0.37rem;
    font-size: .2rem;
    text-align: center;
    background: linear-gradient(90deg, #7056E5 0, #7090F2 100%);
    color: #FFFFFF;
  }

  > .banner-title {
    position: absolute;
    top: 2.3rem;
    right: 3.6rem;
    line-height: .8rem;
    font-size: .7rem;
    font-weight: bold;
    text-align: right;
    color: $md-color-primary;
  }


  > .banner-subTitle {
    position: absolute;
    top: 4.14rem;
    right: 3.6rem;
    line-height: .4rem;
    font-size: .4rem;
  }

  > .banner-verify {
    position: absolute;
    bottom: 0.46rem;
    right: 3.6rem;
    text-align: right;
    line-height: .18rem;
    font-size: .12rem;
    color: #999999;
  }

  > .banner-tip-one {
    position: absolute;
    left: 3.81rem;
    bottom: 0.81rem;
    color: $md-color-primary;
    font-weight: 500;
    font-size: 0.16rem;

    > span {
      color: $md-color-primary;
      font-weight: 500;
      font-size: 0.16rem;
    }
  }

  > .banner-tip-two {
    position: absolute;
    left: 3.83rem;
    bottom: 0.62rem;
    color: #999999;
    font-weight: 500;
    font-size: 0.12rem;

    > span {
      color: #999999;
      font-weight: 500;
      font-size: 0.12rem;
    }
  }
}

.content-item {
  position: relative;
  padding-top: 0.08rem;

  &:nth-child(odd) {
    background: #FAFBFF;
  }
}

#video {
  height: 7.35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;

  > .video-container {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    > .video {
      width: 12rem;
      height: 6.75rem;
      border-radius: 0.2rem;
    }

    > .playBtn {
      position: absolute;
      width: 0.8rem;
    }
  }
}

#apply {
  height: 5.5rem;

  > .apply-title {
    position: absolute;
    top: 0.95rem;
    left: 0;
    right: 0;
    font-size: 0.44rem;
    line-height: 1;
    font-weight: bold;
    text-align: center;
  }

  > .apply-subTitle {
    position: absolute;
    top: 1.69rem;
    left: 0;
    right: 0;
    font-size: 0.24rem;
    line-height: 1;
    text-align: center;
    color: #666;
  }


  > .apply-content {
    position: absolute;
    top: 2.39rem;
    left: 9rem;
    right: 9rem;
    font-size: 0.2rem;
    line-height: 1;
    text-align: center;
    color: #fff;
    background: $md-color-primary;
    padding: 0.05rem 0;
    border-radius: 0.08rem;
    white-space: nowrap;
  }

  > .apply-for {
    position: absolute;
    top: 3.07rem;
    left: 3.72rem;
    right: 3.72rem;
    display: flex;
    justify-content: space-between;

    > .for-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .for-item-img {
        width: 0.9rem;
      }

      > .for-item-text {
        padding-top: 0.24rem;
        font-size: 0.2rem;
        color: #555;
        line-height: 0.26rem;
        text-align: center;
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }
}

#technology {
  height: 6.6rem;

  > .technology-title {
    position: absolute;
    top: 0.84rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  > .technology-subTitle {
    position: absolute;
    top: 1.58rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.24rem;
    color: #666666;
    line-height: 1;
  }

  > .technology-desc {
    position: absolute;
    top: 2.44rem;
    background: $md-color-primary;
    border-radius: 0.04rem;
    font-size: 0.16rem;
    color: #FFFFFF;
    line-height: 0.16rem;
    padding: 0.05rem 0.14rem;

    &.left {
      left: 3.6rem;
    }

    &.right {
      left: 10.5rem;
    }
  }

  > .technology-img {
    position: absolute;
    top: 2.82rem;
    width: 5.06rem;
    height: 2.96rem;
    border-radius: 0.2rem;

    &.left {
      left: 3.6rem;
    }

    &.right {
      left: 10.5rem;
    }
  }
}

#system {
  height: 6.45rem;

  > .system-title {
    position: absolute;
    top: 2.52rem;
    left: 11.75rem;
    font-size: 0.44rem;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  > .system-subTitle {
    position: absolute;
    top: 3.19rem;
    left: 11.75rem;
    font-size: 0.32rem;
    color: #333333;
    line-height: 1;
  }

  > .system-img {
    position: absolute;

    &.left {
      left: 3.6rem;
      top: 0.72rem;
      width: 4.1rem;
      height: 5rem;
    }

    &.right {
      left: 6.74rem;
      top: 2.61rem;
      width: 4.1rem;
      height: 2.4rem;
    }
  }
}

#pelvic {
  height: 6.45rem;

  > .pelvic-title {
    position: absolute;
    top: 2.52rem;
    left: 3.6rem;
    font-size: 0.44rem;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  > .pelvic-subTitle {
    position: absolute;
    top: 3.19rem;
    left: 3.6rem;
    font-size: 0.32rem;
    color: #333333;
    line-height: 1;
  }

  > .pelvic-img {
    position: absolute;

    &.right {
      left: 11.5rem;
      top: 0.73rem;
      width: 4.1rem;
      height: 5rem;
    }

    &.left {
      left: 8.7rem;
      top: 1.51rem;
      width: 4.1rem;
      height: 2.4rem;
      border-radius: 0.2rem;
    }
  }
}

#dimension {
  height: 6.45rem;

  > .dimension-title {
    position: absolute;
    top: 2.49rem;
    left: 10.48rem;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 1;
  }

  > .dimension-subTitle {
    position: absolute;
    top: 3.29rem;
    left: 10.48rem;
    font-size: 0.24rem;
    line-height: 1;
    color: #666666;

    &.extra {
      top: 3.71rem;
      right: 3.6rem;
      line-height: 0.3rem;
    }
  }


  > .dimension-img {
    position: absolute;
    top: 1.07rem;
    left: 3.56rem;
    width: 4.94rem;
  }
}

#wireless {
  height: 6.45rem;

  > .wireless-title {
    position: absolute;
    top: 2.32rem;
    left: 3.6rem;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.6rem;
  }

  > .wireless-subTitle {
    position: absolute;
    top: 3.69rem;
    left: 3.6rem;
    font-size: 0.24rem;
    line-height: 0.3rem;
    color: #666666;
  }


  > .wireless-img {
    position: absolute;

    &.left {
      width: 3.83rem;
      top: 3.18rem;
      left: 9.23rem;
    }

    &.right {
      width: 4.1rem;
      height: 5rem;
      top: 0.73rem;
      left: 11.5rem;
    }
  }
}

#privacy {
  height: 6.45rem;

  > .privacy-title {
    position: absolute;
    top: 2.62rem;
    left: 12.21rem;
    font-size: 0.44rem;
    font-weight: bold;
    color: #333333;
    line-height: 1;
  }

  > .privacy-subTitle {
    position: absolute;
    top: 3.29rem;
    left: 12.21rem;
    font-size: 0.32rem;
    color: #333333;
    line-height: 1;
  }

  > .privacy-img {
    position: absolute;

    &.right {
      left: 6.83rem;
      top: 1.12rem;
      width: 3.3rem;
      height: 4.2rem;
    }

    &.left {
      left: 3.6rem;
      top: 0.73rem;
      width: 4.1rem;
      height: 5rem;
    }
  }
}

#assess {
  height: 6.45rem;

  > .assess-title {
    position: absolute;
    top: 2.51rem;
    left: 3.6rem;
    font-size: 0.44rem;
    font-weight: bold;
    color: #333333;
    line-height: 0.6rem;
  }

  > .assess-subTitle {
    position: absolute;
    top: 3.96rem;
    left: 3.6rem;
    font-size: 0.24rem;
    color: #666666;
    line-height: 1;

    &.extra {
      top: 4.35rem;
    }
  }

  > .assess-img {
    position: absolute;
    top: 1.1rem;
    left: 10.93rem;
    width: 4.75rem;
  }
}

#train {
  height: 6.45rem;

  > .train-title {
    position: absolute;
    top: 2.23rem;
    left: 11.43rem;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 0.6rem;
  }

  > .train-subTitle {
    position: absolute;
    top: 3.53rem;
    left: 11.43rem;
    font-size: 0.24rem;
    line-height: 1;
    color: #666666;
  }

  > .train-img {
    position: absolute;
    top: 0.22rem;
    left: 3.6rem;
    width: 6.01rem;
    height: 6.08rem;
  }
}

#protect {
  height: 6.45rem;

  > .protect-title {
    position: absolute;
    top: 2.11rem;
    left: 3.6rem;
    font-size: 0.44rem;
    font-weight: bold;
    color: #333333;
    line-height: 0.6rem;
  }

  > .protect-subTitle {
    position: absolute;
    top: 3.43rem;
    left: 3.6rem;
    font-size: 0.24rem;
    color: #666666;
    line-height: 1;

    &.extra {
      top: 3.82rem;
    }
  }

  > .protect-img {
    position: absolute;
    top: 0.53rem;
    left: 10.09rem;
    width: 5.5rem;
  }
}

#pattern {
  height: 7rem;

  > .pattern-title {
    position: absolute;
    top: 0.72rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 1;
  }

  > .pattern-subTitle {
    position: absolute;
    top: 1.43rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.24rem;
    color: #666666;
    line-height: 1;
  }

  > .pattern-img {
    position: absolute;
    top: 2.22rem;
    left: 6.15rem;
    width: 6.9rem;
  }
}

#expr {
  height: 8rem;

  > .expr-title {
    position: absolute;
    top: 0.95rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 1;
  }

  > .expr-subTitle {
    position: absolute;
    top: 1.66rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.24rem;
    color: #666666;
    line-height: 1;
  }

  > .expr-img {
    position: absolute;
    top: 3.68rem;
    left: 7.64rem;
    width: 4.6rem;
  }

  > .expr-item {
    position: absolute;
    width: 1.2rem;
    height: 1.9rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &.one {
      top: 4.3rem;
      left: 3.88rem;
    }

    &.two {
      top: 2.79rem;
      left: 5.83rem;
    }

    &.three {
      top: 5.38rem;
      left: 5.83rem;
    }

    &.four {
      top: 2.79rem;
      left: 12.58rem;
    }

    &.five {
      top: 5.38rem;
      left: 12.58rem;
    }

    &.six {
      top: 4.3rem;
      left: 14.28rem;
    }

    > .expr-item-img {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
    }

    > .expr-item-title {
      font-weight: 500;
      font-size: 0.2rem;
      color: #555555;
      line-height: 1;
      white-space: nowrap;
    }

    > .expr-item-desc {
      font-size: 0.18rem;
      color: #999999;
      line-height: 1;
      white-space: nowrap;
    }
  }
}

#connect {
  height: 6.8rem;
  overflow: hidden;

  > .connect-circle {
    position: absolute;
    left: 1.4rem;
    top: 1.2rem;
  }

  > .connect-title {
    position: absolute;
    top: 0.95rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 1;
  }

  > .connect-subTitle {
    position: absolute;
    top: 1.71rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.24rem;
    color: #666666;
    line-height: 1;
  }

  > .connect-img {
    position: absolute;

    &.left {
      top: 3.12rem;
      left: 4.34rem;
      width: 4rem;
      height: 2.5rem;
    }

    &.right {
      top: 2.43rem;
      left: 12.04rem;
      width: 3.1rem;
      height: 3.7rem;
    }

    &.append {
      top: 3.05rem;
      left: 7.33rem;
      width: 5.19rem;
      height: 1.48rem;
    }
  }
}

#research {
  height: 8rem;
  overflow: hidden;

  > .research-title {
    position: absolute;
    top: 0.95rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 1;
  }

  > .research-subTitle {
    position: absolute;
    top: 1.71rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.24rem;
    color: #666666;
    line-height: 1;
  }

  > .research-label {
    position: absolute;
    top: 2.19rem;
    left: 8.5rem;
    right: 8.5rem;
    font-size: 0.22rem;
    line-height: 1;
    text-align: center;
    color: #fff;
    background: $md-color-primary;
    padding: 0.11rem 0;
    border-radius: 0.08rem;
    white-space: nowrap;
  }

  > .research-img {
    position: absolute;
    top: 2.49rem;
    left: 5.6rem;
    width: 8.06rem;
    height: 5.99rem;
  }

  > .research-tip {
    position: absolute;
    top: 7.02rem;
    left: 6.27rem;
    font-size: 0.12rem;
    color: #999999;
    line-height: 0.25rem;
  }
}

#patent {
  height: 6.5rem;

  > .patent-title {
    position: absolute;
    top: 0.65rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.44rem;
    font-weight: bold;
    line-height: 1;
  }

  > .patent-subTitle {
    position: absolute;
    top: 1.43rem;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 0.24rem;
    color: #666666;
    line-height: 1;
  }

  > .patent-img {
    position: absolute;
    top: 2.49rem;
    left: 5.6rem;
    width: 8.06rem;
    height: 5.99rem;
  }

  > .patent-list {
    position: absolute;
    top: 2.46rem;
    left: 3.58rem;
    right: 3.58rem;
    display: flex;
    justify-content: space-between;

    > .patent-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .patent-item-img {
        width: 1.9rem;
        height: 2.5rem;
      }

      > .patent-item-name {
        margin-top: 0.22rem;
        font-weight: 500;
        font-size: 0.2rem;
        color: #555555;
        line-height: 1;
        white-space: nowrap;
        width: 100%;
        text-align: center;
      }

      > .patent-item-desc {
        margin-top: 0.1rem;
        height: 0.16rem;
        font-size: 0.16rem;
        color: #999999;
        line-height: 1;
        white-space: nowrap;
        width: 100%;
        text-align: center;
      }
    }
  }
}

</style>
